<template>
  <Banner
    bg-image="blue-bg.jpg"
    talk-img="help-for-someone-supportive-friend.png"
  >
    <template v-slot:title>
      Help for someone with suicidal thoughts, intentions or plans</template
    >
    <template v-slot:subtitle>
      Your friend/family member may be saying things that sound as if they are
      considering suicide. You may not be sure what to do to help, whether you
      should take talk of suicide seriously, or if your intervention might make
      the situation worse. Taking supportive steps is always the best choice.
    </template>
  </Banner>

  <section class="pt-10 gradient-white md:pt-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        Please get help immediately if your friend/family member is:
      </div>

      <ul class="about-ul">
        <li>
          Seeking access to lethal means—whether that is online or physically—in
          a moment of despair.
        </li>
        <li>
          Talking, writing, or posting on social media about death and suicide
          when these actions are out of the ordinary for the person.
        </li>
        <li>Their thoughts of suicide have progressed to a plan.</li>
        <li>
          They are threatening to hurt or kill themselves – especially if they
          have a weapon or other item that could cause harm.
        </li>
      </ul>
    </div>
  </section>
  <section class="pt-10 gradient-white md:pt-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="text-xl font-bold text-center call-text md:text-3xl text-red">
        To get immediate help, call 911 <br />
        or take them to the nearest emergency room
      </div>
    </div>
  </section>

  <EmergencySupport>
    <template v-slot:title> Important: Don’t leave your friend alone </template>
    <template v-slot:content>
      Make sure there is someone with them at all times, weather it is you or
      another close/trusted friend or family member.
      <p class="pb-2"></p>
    </template>
  </EmergencySupport>

  <div class="flex justify-center p-8">
    <GreenBtn
      text="Learn how to get help for someone else"
      name="HelpForSomeoneElse"
    />
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import EmergencySupport from "@/components/EmergencySupport.vue";
import GreenBtn from "@/components/GreenBtn.vue";
export default {
  name: "GetHelpForSomeoneElse",
  components: {
    Banner,
    EmergencySupport,
    GreenBtn,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
ul.about-ul li {
  padding: 8px 0 8px 50px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
ul.about-ul li::after {
  content: "";
  background: #22b46e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>
